var _window_heap;
import { logger } from '../services/logger/logger.service';
/* eslint-disable */ // prettier-ignore
// @ts-ignore: Minified heap.js
window.heapReadyCb = window.heapReadyCb || [], window.heap = window.heap || [], heap.load = function(e, t) {
    window.heap.envId = e, window.heap.clientConfig = t = t || {}, window.heap.clientConfig.shouldFetchServerConfig = !1;
    var a = document.createElement("script");
    a.type = "text/javascript", a.async = !0, a.src = "https://cdn.us.heap-api.com/config/" + e + "/heap_config.js";
    var r = document.getElementsByTagName("script")[0];
    r.parentNode.insertBefore(a, r);
    var n = [
        "init",
        "startTracking",
        "stopTracking",
        "track",
        "resetIdentity",
        "identify",
        "getSessionId",
        "getUserId",
        "getIdentity",
        "addUserProperties",
        "addEventProperties",
        "removeEventProperty",
        "clearEventProperties",
        "addAccountProperties",
        "addAdapter",
        "addTransformer",
        "addTransformerFn",
        "onReady",
        "addPageviewProperties",
        "removePageviewProperty",
        "clearPageviewProperties",
        "trackPageview"
    ], i = function i(e) {
        return function() {
            var _$t = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
                name: e,
                fn: function fn() {
                    heap[e] && heap[e].apply(heap, _$t);
                }
            });
        };
    };
    for(var p = 0; p < n.length; p++)heap[n[p]] = i(n[p]);
};
/* eslint-enable */ var truthyValues = [
    'true',
    '1',
    'yes'
];
var _process_env_SAAS_APP_ENABLE_TRACK;
var enableTracking = ((_process_env_SAAS_APP_ENABLE_TRACK = process.env.SAAS_APP_ENABLE_TRACK) !== null && _process_env_SAAS_APP_ENABLE_TRACK !== void 0 ? _process_env_SAAS_APP_ENABLE_TRACK : '').toLowerCase();
var mode = '';
if (truthyValues.includes(enableTracking)) {
    var _window_heap1;
    mode = 'prod';
    (_window_heap1 = window.heap) === null || _window_heap1 === void 0 ? void 0 : _window_heap1.load('3476161595');
} else {
    var _window_heap2;
    mode = 'dev';
    (_window_heap2 = window.heap) === null || _window_heap2 === void 0 ? void 0 : _window_heap2.load('3743651019');
}
(_window_heap = window.heap) === null || _window_heap === void 0 ? void 0 : _window_heap.onReady(function() {
    logger.info('[HEAP] Loaded in "'.concat(mode, '" mode'));
});
